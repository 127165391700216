//ManageBooks.tsx

import React, { MouseEvent, useEffect, useState, useCallback } from 'react';
import { Spin, Modal, Button, Input, Upload, Card, Divider, Select, message, Dropdown, Menu } from 'antd';
import MenuInfo from "antd/lib/menu/MenuContext"
import { UploadOutlined, TranslationOutlined, DeleteOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { User } from "firebase/auth";
import axios, { AxiosError } from 'axios';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { getAuth } from "firebase/auth"; // Import getAuth

import BookUploadForm from './BookUploadForm';

const { Option } = Select;

const BookItem = styled.div` 
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  margin-right: 20px;
`;

const AuthorBooksContainer = styled.div`
  margin-top: 20px; // Add some space between different authors
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Header = styled.h2`
  margin: 0; // Remove margin to align properly with the button
`;

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const BookCover = styled.div<{ coverUrl?: string }>`
  background-image: url(${props => props.coverUrl || '/default-cover.jpg'});
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  height: 213px; 
  width: 134px; 
  margin-bottom: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  }
`;

const BookTitle = styled.div<{ coverUrl?: string }>`
  display: ${props => props.coverUrl ? 'none' : 'flex'};
  align-items: center;
  justify-content: center;
  background-color: black;
  color: white;
  height: 100%;
  text-align: center;
  padding: 10px;
`;

const BookGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(134px, 1fr)); // Adjusted min-width to match new BookCover width
  grid-gap: 20px;
  margin-top: 20px;
`;

const ModalTitle = styled.h3`
  text-align: center;
  margin: 0;
`;

// Interface for books
interface IBook {
  bookguid: string;
  title: string;
  author: string; 
  series: string; 
  seriesBookNumber?: number;
  coverUrl?: string;
}

interface Series {
  name: string;
  books: IBook[];
}

interface AuthorSeriesGroup {
  author: string;
  series: Series[];
}

interface BookUploadFormProps {
  onUpload: (formData: FormData) => Promise<void>;
  onReset: () => void;
  authors: string[];
  seriesList: string[];
}

interface ErrorResponse {
  message?: string;
  status?: string;
}

interface BookItemWithMenuProps {
  book: IBook;
  onDelete: (event: React.MouseEvent<HTMLElement> | typeof MenuInfo, bookGuid: string) => void;
  onTranslate: (bookGuid: string) => void;
  onMarketing: (bookGuid: string) => void;
  onAudio: (bookGuid: string) => void;
  isMenuOpen: boolean;
  toggleMenu: (e: React.MouseEvent, bookGuid: string) => void;
}

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;


const BookItemWithMenu: React.FC<BookItemWithMenuProps> = ({ book, onDelete, onTranslate, onMarketing, onAudio, isMenuOpen, toggleMenu }) => {
const handleMenuItemClick = (action: 'translate' | 'delete' | 'marketing' | 'audio') => {
    if (action === 'translate') {
      onTranslate(book.bookguid);
    } else if (action === 'delete') {
      onDelete({} as React.MouseEvent<HTMLElement>, book.bookguid);
    } else if (action === 'marketing') {
	  onMarketing(book.bookguid);
	} else if (action === 'audio') {
	  onAudio(book.bookguid);
	}
  };

  return (
    <BookItem onClick={(e) => toggleMenu(e, book.bookguid)}>
      <BookCover coverUrl={book.coverUrl}>
        <BookTitle coverUrl={book.coverUrl}>{book.title}</BookTitle>
      </BookCover>
      {isMenuOpen && (
        <div style={{
          position: 'absolute',
          left: '100%',
          top: 0,
          backgroundColor: 'white',
          boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
          zIndex: 1000,
        }}>
          <Menu>
            <Menu.Item key="translate" icon={<TranslationOutlined />} onClick={() => handleMenuItemClick('translate')}>
              Translations
            </Menu.Item>
            {/*
			<Menu.Item key="marketing" icon={<ShoppingCartOutlined />} onClick={() => handleMenuItemClick('marketing')}>
              Marketing
            </Menu.Item>
			
            <Menu.Item key="audio" icon={<ShoppingCartOutlined />} onClick={() => handleMenuItemClick('audio')}>
              AudioBooks
            </Menu.Item>			
			*/}
            <Menu.Item key="delete" icon={<DeleteOutlined />} onClick={() => handleMenuItemClick('delete')}>
              Delete
            </Menu.Item>
          </Menu>
        </div>
      )}
    </BookItem>
  );
};

// Main component
const BookManagementTab: React.FC<{ user: User | null }> = ({ user }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [bookDetails, setBookDetails] = useState<IBook[]>([]);
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
  const [formKey, setFormKey] = useState(0); // New state to hold the key for the form
  const navigate = useNavigate();
  const [authorSeriesGroups, setAuthorSeriesGroups] = useState<AuthorSeriesGroup[]>([]);
  const [uniqueAuthors, setUniqueAuthors] = useState<string[]>([]);
  const [uniqueSeries, setUniqueSeries] = useState<string[]>([]);  
  const pageDisabled = false;
  const disabledMessage = "After a lot of feedback, we're making improvements. Check back soon! -Team ScribeShadow";
  const [openMenuBookGuid, setOpenMenuBookGuid] = useState<string | null>(null);
  const [selectedAuthor, setSelectedAuthor] = useState<string | null>(null);
  const [selectedSeries, setSelectedSeries] = useState<string | null>(null);
  const [filteredAuthors, setFilteredAuthors] = useState<string[]>([]);
  const [filteredSeries, setFilteredSeries] = useState<string[]>([]);



const handleMarketingClick = (bookGuid: string) => {
   navigate(`/marketing/${bookGuid}`);
}; 

const handleTranslateClick = (bookGuid: string) => {
   navigate(`/book-detail/${bookGuid}`);
};  

const handleAudiobookClick = (bookGuid: string) => {
   navigate(`/audio/${bookGuid}`);
};  


const toggleMenu = (e: React.MouseEvent, bookGuid: string) => {
  e.stopPropagation();
  setOpenMenuBookGuid(prevGuid => prevGuid === bookGuid ? null : bookGuid);
};

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (openMenuBookGuid && !(event.target as Element).closest('.book-item')) {
        setOpenMenuBookGuid(null);
      }
    };

    const handleClickOutsideWrapper = (event: Event) => {
      handleClickOutside(event as unknown as MouseEvent);
    };

    document.addEventListener('click', handleClickOutsideWrapper);

    return () => {
      document.removeEventListener('click', handleClickOutsideWrapper);
    };
  }, [openMenuBookGuid]);
  
  const handleModalOpen = () => {
    setIsUploadModalVisible(true);
    setFormKey(prevKey => prevKey + 1); // Reset the form when modal opens
  };

  const handleModalClose = () => {
    setIsUploadModalVisible(false);
    setFormKey(prevKey => prevKey + 1); // Reset the form when modal closes
  };

  const fetchBooks = useCallback(async () => {
    setIsLoading(true);
	setBookDetails([]);
    if (!user) {
      setBookDetails([]);
	  console.error("User is not authenticated");
      setIsLoading(false);
      return;
    }

  try {
    const response = await axios.get(`https://askpoeai-nswdzfky7q-uc.a.run.app/uploadedbooks/user/${user.uid}`);
    setBookDetails(response.data);

    // Now correctly placed within the try block
    const fetchedAuthors = new Set<string>();
    const fetchedSeries = new Set<string>();
    response.data.forEach((book: IBook) => {
      fetchedAuthors.add(book.author);
      if (book.series) {
        fetchedSeries.add(book.series);
      }
    });
    setUniqueAuthors(Array.from(fetchedAuthors));
    setUniqueSeries(Array.from(fetchedSeries));
  } catch (error) {
    console.error("Error fetching books: ", (error as AxiosError).response?.data || (error as AxiosError).message);
  }
  setIsLoading(false);
}, [user]);

const groupBooks = (books: IBook[]) => {
  // First, sort the books array
  const sortedBooks = [...books].sort((a, b) => {
    // First sort by author
    const authorCompare = (a.author || '').localeCompare(b.author || '');
    if (authorCompare !== 0) return authorCompare;

    // Then sort by series name
    const seriesA = a.series || 'No Series';
    const seriesB = b.series || 'No Series';
    const seriesCompare = seriesA.localeCompare(seriesB);
    if (seriesCompare !== 0) return seriesCompare;

    // Then sort by series number
    const numberA = a.seriesBookNumber || 0;
    const numberB = b.seriesBookNumber || 0;
    if (numberA !== numberB) return numberA - numberB;

    // Finally, sort alphabetically by title if series numbers are the same
    return (a.title || '').localeCompare(b.title || '');
  });

  const grouped = sortedBooks.reduce((acc: AuthorSeriesGroup[], book: IBook) => {
    const bookSeries = book.series || "No Series";
    const authorIndex = acc.findIndex(g => g.author === book.author);

    if (authorIndex > -1) {
      const seriesIndex = acc[authorIndex].series.findIndex(s => s.name === bookSeries);

      if (seriesIndex > -1) {
        acc[authorIndex].series[seriesIndex].books.push(book);
      } else {
        // Insert new series in alphabetical order
        const newSeries = { name: bookSeries, books: [book] };
        const insertIndex = acc[authorIndex].series.findIndex(s => s.name.localeCompare(bookSeries) > 0);
        if (insertIndex === -1) {
          acc[authorIndex].series.push(newSeries);
        } else {
          acc[authorIndex].series.splice(insertIndex, 0, newSeries);
        }
      }
    } else {
      // Insert new author in alphabetical order
      const newAuthorGroup = {
        author: book.author,
        series: [{ name: bookSeries, books: [book] }]
      };
      const insertIndex = acc.findIndex(g => g.author.localeCompare(book.author) > 0);
      if (insertIndex === -1) {
        acc.push(newAuthorGroup);
      } else {
        acc.splice(insertIndex, 0, newAuthorGroup);
      }
    }
    return acc;
  }, []);

  // Sort books within each series
  grouped.forEach(group => {
    group.series.forEach(serie => {
      serie.books.sort((a, b) => {
        const numberA = a.seriesBookNumber || 0;
        const numberB = b.seriesBookNumber || 0;
        if (numberA !== numberB) return numberA - numberB;
        return (a.title || '').localeCompare(b.title || '');
      });
    });
  });

  setAuthorSeriesGroups(grouped);
};
  
  useEffect(() => {
    if (bookDetails.length > 0) {
      groupBooks(bookDetails);
    }
  }, [bookDetails]);  
 
  
useEffect(() => {
  if (!user) {
    // Clear bookDetails if there is no user logged in
    setBookDetails([]);
    setAuthorSeriesGroups([]);
    setUniqueAuthors([]);
    setUniqueSeries([]);
    //console.error("User is not authenticated");
	//console.log("bookDetails: "+bookDetails);
    setIsLoading(false);
  } else {
    fetchBooks();
  }
}, [user, fetchBooks]);  

const deleteBook = async (event: React.MouseEvent<HTMLElement>, bookGuid: string) => {
  //event.stopPropagation(); // Prevent the event from bubbling up

  Modal.confirm({
    title: 'Are you sure you want to delete this book?',
    content: 'This action cannot be undone.',
    onOk: async () => {
      if (!user) {
        console.error("User is not authenticated");
        return;
      }

      try {
        await axios.delete(`https://askpoeai-nswdzfky7q-uc.a.run.app/uploadedbooks/${bookGuid}`, {
          data: { user_id: user.uid }
        });

        // Update both bookDetails and authorSeriesGroups
        const updatedBookDetails = bookDetails.filter(book => book.bookguid !== bookGuid);
        setBookDetails(updatedBookDetails);
        groupBooks(updatedBookDetails);
      } catch (error) {
        console.error("Error deleting book: ", error);
      }
    },
  });
};

const handleUpload = async (formData: FormData) => {
  if (!user) {
    console.error("User is not authenticated");
    throw new Error("User is not authenticated");
  }

  setIsLoading(true);
  formData.append('user_id', user.uid);

  try {
    console.log("Uploading Book");
	const response = await axios.post('https://askpoeai-nswdzfky7q-uc.a.run.app/upload-book/', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    if (response.data.status === 'error') {
      throw new Error(response.data.message);
    }

    // Close the modal
    handleModalClose();

    // Navigate to the book detail page using the bookguid
    const newBookGuid = response.data.book_id;
    navigate(`/book-detail/${newBookGuid}`);
	
  } catch (error) {
    console.error("Error uploading file: ", error);
    throw error; // Propagate the error to be caught in BookUploadForm
  } finally {
    setIsLoading(false);
  }
};

//functions for filtering
  useEffect(() => {
    if (bookDetails.length > 0) {
      const authors = new Set<string>();
      const series = new Set<string>();

      bookDetails.forEach((book) => {
        authors.add(book.author);
        if (book.series) {
          series.add(book.series);
        }
      });

      setUniqueAuthors(Array.from(authors));
      setUniqueSeries(Array.from(series));
    }
  }, [bookDetails]);

  useEffect(() => {
    if (selectedAuthor) {
      const filteredSeries = bookDetails
        .filter((book) => book.author === selectedAuthor)
        .map((book) => book.series)
        .filter((series): series is string => !!series);
      setFilteredSeries(Array.from(new Set(filteredSeries)));
    } else {
      setFilteredSeries(uniqueSeries);
    }

    if (selectedSeries) {
      const filteredAuthors = bookDetails
        .filter((book) => book.series === selectedSeries)
        .map((book) => book.author);
      setFilteredAuthors(Array.from(new Set(filteredAuthors)));
    } else {
      setFilteredAuthors(uniqueAuthors);
    }
  }, [selectedAuthor, selectedSeries, bookDetails, uniqueAuthors, uniqueSeries]);

  const handleAuthorChange = (value: string | null) => {
    setSelectedAuthor(value);
    if (!value) {
      setSelectedSeries(null);
    }
  };

  const handleSeriesChange = (value: string | null) => {
    setSelectedSeries(value);
    if (!value) {
      setSelectedAuthor(null);
    }
  };

  const clearFilters = () => {
    setSelectedAuthor(null);
    setSelectedSeries(null);
  };

  const filteredGroups = authorSeriesGroups.filter(group => {
    if (selectedAuthor && group.author !== selectedAuthor) {
      return false;
    }
    if (selectedSeries) {
      return group.series.some(serie => serie.name === selectedSeries);
    }
    return true;
  });

    return (
    <Spin spinning={isLoading}>
      <div style={{ backgroundColor: '#f0f2f5', padding: '2em' }}>
        <Card style={{ backgroundColor: '#ffffff', padding: '2em', borderRadius: '5px' }}>
        {user && !user?.emailVerified ? (
          <p>Please verify your email to access this page.</p>
        ) : (
          <>		
          {pageDisabled ? (
            <p>{disabledMessage}</p>
          ) : (
            <>
          {user ? (
            <>
            <HeaderContainer>
              <Header>My Books</Header>
              <Button icon={<UploadOutlined />} onClick={handleModalOpen}>Upload Book</Button>
            </HeaderContainer>
			<FilterContainer>
			<Select
				style={{ width: 200, marginRight: 16 }}
				placeholder="Filter by Author"
				onChange={handleAuthorChange}
				value={selectedAuthor}
				allowClear
			>
				{filteredAuthors.sort((a, b) => a.localeCompare(b)).map(author => (
				<Option key={author} value={author}>{author}</Option>
				))}
			</Select>
			<Select
				style={{ width: 200, marginRight: 16 }}
				placeholder="Filter by Series"
				onChange={handleSeriesChange}
				value={selectedSeries}
				allowClear
			>
				{filteredSeries.sort((a, b) => a.localeCompare(b)).map(series => (
				<Option key={series} value={series}>{series}</Option>
				))}
			</Select>
			<Button onClick={clearFilters}>Clear Filters</Button>
			</FilterContainer>
            <Divider />
            <Modal title={<ModalTitle>Upload Book</ModalTitle>} visible={isUploadModalVisible} onCancel={handleModalClose} footer={null}>
              <BookUploadForm
                key={formKey}
                onUpload={handleUpload}
                onReset={handleModalClose}
                authors={uniqueAuthors}
                seriesList={uniqueSeries}
              />
            </Modal>
            <div>
              {filteredGroups.map(group => (
                <AuthorBooksContainer key={group.author}>
                  <h3>{group.author}</h3>
                  {group.series
                    .filter(serie => !selectedSeries || serie.name === selectedSeries)
                    .map(serie => (
                      <div key={serie.name}>
                        <h4>{serie.name}</h4>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                          {serie.books.map(book => (
                            <BookItemWithMenu
                              key={book.bookguid}
                              book={book}
                              onDelete={(event, bookGuid) => deleteBook(event as React.MouseEvent<HTMLElement>, bookGuid)}
                              onMarketing={handleMarketingClick}
							  onAudio={handleAudiobookClick}
                              onTranslate={handleTranslateClick}
                              isMenuOpen={openMenuBookGuid === book.bookguid}
                              toggleMenu={toggleMenu}
                            />
                          ))}
                        </div>
                      </div>
                    ))}
                </AuthorBooksContainer>
              ))}
            </div>
            </>
          ) : (
            <p>Please log in to view your books.</p>
          )}
            </>
          )}	
          </>
        )}		  
        </Card>
      </div>
    </Spin>
  );
};


export default BookManagementTab;
